import React from "react";
import Header from "src/components/Header";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <Header></Header>
        <div className="main row">
          <div className="sidebar three columns">
            <ul className="side-nav">
              <li className="active first overview last">
                <a href="/veelgestelde-vragen-diarree/"> FAQ</a>
              </li>
            </ul>
            <div className="widget">
              <div className="inner">
                <h4>Tips bij diarree</h4>
                <div className="img">
                  <img
                    src="/assets/files/widgets/images/teaser_durchfallmythen.jpeg"
                    width="170"
                    height="102"
                    alt="Tips bij diarree"
                  />
                </div>
                <p>
                  Rond het behandelen van de symptomen van diarree bestaan er
                  nog vele misverstanden en&nbsp;mythes. Hier vind je enkele
                  tips over het behandelen van de symptomen van diarree.
                </p>
                <a href="/feiten-over-diarree/tips-voor-de-behandeling-van-diarree/">
                  Tips bij diarree
                </a>
              </div>
            </div>
            <div className="widget">
              <div className="inner">
                <h4>Reisapotheek</h4>
                <div className="img">
                  <img
                    src="/assets/files/widgets/images/teaser_reisen.jpeg"
                    width="170"
                    height="102"
                    alt="Reisapotheek"
                  />
                </div>
                <p>
                  Wat hoort thuis in de <strong>reisapotheek</strong>? Vind hier
                  alle praktische informatie.
                </p>
                <a href="/diarree-op-reis/reisapotheek/">Meer informatie</a>
              </div>
            </div>
          </div>
          <div className="page nine columns page-45">
            <h1>Veelgestelde vragen over diarree</h1>
            <div className="intro row">
              <div className="twelve columns">
                <p>
                  <a
                    data-close="Alles sluiten"
                    data-open="Alles openklappen"
                    href="#"
                    id="toggleAllQuestions"
                  >
                    Alles openklappen
                  </a>
                </p>
              </div>
            </div>
            <div className="question">
              <h4>
                <a href="/veelgestelde-vragen-diarree/oorzaak-terugkerende-diarree/">
                  Wat moet ik doen als ik niet zeker weet waardoor mijn
                  terugkerende diarree wordt veroorzaakt?
                </a>
              </h4>
              <div className="answer">
                <p />
                <p>
                  Als je&nbsp;<strong>diarree</strong> blijvend of terugkerend
                  is of gepaard gaat met bloed of andere symptomen, moet je je
                  arts raadplegen – vooral als je onlangs in een minder
                  ontwikkeld land bent geweest. Als er geen duidelijke reden is
                  voor je diarree en je lijkt geen van de oorzaken te hebben die
                  op deze website beschreven staan, kan het te wijten zijn aan
                  voedselintolerantie, of misschien gewoon aan stress.{" "}
                  <a href="/imodium-behandelen-diarree/">IMODIUM®</a> kan in een
                  aantal van deze gevallen helpen, maar het is belangrijk eerst
                  advies te vragen aan je arts.
                </p>
                <p />
              </div>
            </div>
            <div className="question">
              <h4>
                <a href="/veelgestelde-vragen-diarree/geschikte-behandeling-diarree-zonder-inslikken-tabletten/">
                  Welke behandeling is voor mij geschikt als ik hopeloos ben in
                  het inslikken van tabletten?
                </a>
              </h4>
              <div className="answer">
                <p />
                <p>
                  Maak je geen zorgen, wanneer diarree de kop opsteekt kan je
                  nog altijd snel verlichting krijgen met{" "}
                  <a href="/imodium-behandelen-diarree/imodium-instant/">
                    IMODIUM® Instant smelttabletten
                  </a>
                  . Deze tabletten zijn zodanig gemaakt dat ze binnen enkele
                  seconden op je tong smelten, zonder dat je water nodig hebt.
                </p>
                <p />
              </div>
            </div>
            <div className="question">
              <h4>
                <a href="/veelgestelde-vragen-diarree/waarom-diarree-als-je-grote-hoeveelheden-eet-en-drinkt/">
                  Waarom lijk ik altijd diarree te hebben wanneer ik grote
                  hoeveelheden eet en drink?
                </a>
              </h4>
              <div className="answer">
                <p />
                <p>
                  Wanneer je te veel eet en drinkt, moet je spijsvertering te
                  snel werken, wat <strong>diarree</strong>&nbsp;kan
                  veroorzaaken. Probeer dus altijd verstandig te eten en vermijd
                  overdaad! Als je toch diarree krijgt, kan één dosis{" "}
                  <a href="/imodium-behandelen-diarree/">IMODIUM®</a> de
                  symptomen snel verlichten en je weer op de been helpen.
                </p>
                <p />
              </div>
            </div>
            <div className="question">
              <h4>
                <a href="/veelgestelde-vragen-diarree/wat-moet-ik-doen-als-mijn-kinderen-diarree-krijgen/">
                  Wat moet ik doen als mijn kinderen diarree krijgen?
                </a>
              </h4>
              <div className="answer">
                <p />
                <p>
                  <strong>Kinderen</strong> zijn minder goed in staat om te gaan
                  met verlies van lichaamsvocht door <strong>diarree</strong>{" "}
                  dan volwassenen; het belangrijkste is dus het vocht snel
                  opnieuw op peil te brengen. Als het probleem blijft aanhouden
                  of als het kind jonger is dan 8 jaar, is het aangeraden je
                  arts te raadplegen.
                </p>
                <p>
                  Kinderen ouder dan 8 jaar kunnen snel worden behandeld met{" "}
                  <a href="/imodium-behandelen-diarree/">IMODIUM®</a>.
                </p>
                <p>
                  In ons hoofdstuk{" "}
                  <a href="/diarree-bij-kinderen/">diarree bij kinderen</a>
                  &nbsp;vind je meer informatie hierover.
                </p>
                <p />
              </div>
            </div>
            <div className="question">
              <h4>
                <a href="/veelgestelde-vragen-diarree/schaamte-om-een-middel-tegen-diarree-te-vragen-wat-kan-je-doen/">
                  Ik schaam me om een geneesmiddel tegen diarree te vragen bij
                  de apotheker. Wat kan ik doen?
                </a>
              </h4>
              <div className="answer">
                <p />
                <p>
                  Diarree kan op veel manieren onaangenaam zijn, vooral als je
                  bij de apotheker staat en er staat nog een hele rij wachtenden
                  achter je. Maar denk eraan dat diarree een van de meest
                  voorkomende klachten is waarmee je apotheker te maken krijgt.
                  Apothekers zijn opgeleid om je professioneel advies te geven
                  en begrip te tonen en zij kunnen je de juiste{" "}
                  <a href="/imodium-behandelen-diarree/">IMODIUM®</a>
                  -behandeling aanbevelen. Als je jezelf erg schaamt, waarom
                  vraag je dan niet of je de apotheker privé kunt spreken?
                </p>
                <p />
              </div>
            </div>
            <div className="question">
              <h4>
                <a href="/veelgestelde-vragen-diarree/waarom-krijg-je-diarree-tijdens-je-maandstonden/">
                  Waarom krijg ik vaak diarree tijdens mijn menstruatie?
                </a>
              </h4>
              <div className="answer">
                <p />
                <p>
                  Helaas krijgen veel vrouwen diarree tijdens hun menstruatie.
                  Er wordt verondersteld dat dit wordt veroorzaakt door
                  hormonale wijzigingen. Maar laat het je maand niet vergallen;
                  je kunt de symptomen het snel en efficiënt behandelen met{" "}
                  <a href="/imodium-behandelen-diarree/">IMODIUM®</a>.
                </p>
                <p>
                  Voor meer informatie, neem een kijkje bij{" "}
                  <a href="/feiten-over-diarree/diarree-tijdens-uw-menstruatie/">
                    diarree tijdens je menstruatie.
                  </a>
                </p>
                <p />
              </div>
            </div>
            <div className="question">
              <h4>
                <a href="/veelgestelde-vragen-diarree/wat-kan-je-eraan-doen-als-je-maag-reageert-op-sterk-gekruid-voedsel/">
                  Mijn maag reageert vaak gevoelig op sterk gekruid voedsel. Wat
                  kan ik daaraan doen?
                </a>
              </h4>
              <div className="answer">
                <p />
                <p>
                  Sterk gekruid voedsel is een veel voorkomende oorzaak van
                  diarree – sommige spijsverteringsstelsels kunnen dit gewoon
                  niet aan. Eén dosis{" "}
                  <a href="/imodium-behandelen-diarree/">IMODIUM®</a> kan je
                  symptomen snel verlichten.
                </p>
                <p />
              </div>
            </div>
            <div className="question">
              <h4>
                <a href="/veelgestelde-vragen-diarree/waarom-krijg-ik-last-van-mijn-maag-wanneer-ik-zenuwachtig-ben/">
                  Wanneer ik een speech moet geven, word ik zo zenuwachtig dat
                  ik maagproblemen krijg. Hoe komt dat?
                </a>
              </h4>
              <div className="answer">
                <p />
                <p>
                  Diarree die wordt veroorzaakt door angst om in het openbaar te
                  moeten presteren, komt zo vaak voor dat hij zelfs een eigen
                  naam heeft – PAD (Performance Anxiety Diarrhoea) of{" "}
                  <strong>faalangstdiarree</strong>. Als je weet dat je hieraan
                  lijdt, kan je{" "}
                  <a href="/imodium-behandelen-diarree/imodium-instant/">
                    IMODIUM® Instant smelttabletten
                  </a>{" "}
                  voorzien. Dit kan u het vertrouwen geven dat je nodig hebt om
                  het beste van uzelf te geven.
                </p>
                <p>
                  Voor meer informatie, neem een kijkje bij&nbsp;
                  <a href="/feiten-over-diarree/stress-en-diarree/">
                    Stress en diarree
                  </a>
                  .
                </p>
                <p />
              </div>
            </div>
            <div className="question">
              <h4>
                <a href="/veelgestelde-vragen-diarree/kan-imodium-verstopping-veroorzaken/">
                  Kan IMODIUM® verstopping veroorzaken?
                </a>
              </h4>
              <div className="answer">
                <p />
                <p>
                  <a href="/imodium-behandelen-diarree/">IMODIUM®</a>{" "}
                  veroorzaakt zelden verstopping. Indien je jezelf aan de
                  aanbevolen dosis houdt, kan IMODIUM® je snel en doeltreffend
                  verlossen van diarree, vaak al met één dosis. Denk er echter
                  aan dat je darm bij diarree vaak helemaal leeg is. Daarna kan
                  het één à twee dagen duren voor deze zich opnieuw vult.
                </p>
                <p />
              </div>
            </div>
            <div className="question">
              <h4>
                <a href="/veelgestelde-vragen-diarree/gebruik-van-imodium-bij-zwangerschap-of-borstvoeding/">
                  Mag ik IMODIUM® gebruiken als ik zwanger ben of borstvoeding
                  geef?
                </a>
              </h4>
              <div className="answer">
                <p />
                <p>
                  Het is niet aanbevolen{" "}
                  <a href="/imodium-behandelen-diarree/">IMODIUM®</a> te
                  gebruiken tijdens zwangerschap of borstvoeding. Vraag je arts
                  of apotheker om een alternatieve behandeling.
                </p>
                <p />
              </div>
            </div>
            <div className="question">
              <h4>
                <a href="/veelgestelde-vragen-diarree/mogen-kinderen-imodium-gebruiken/">
                  Mogen kinderen IMODIUM® gebruiken?
                </a>
              </h4>
              <div className="answer">
                <p />
                <p>
                  Alleen kinderen vanaf 8 jaar mogen{" "}
                  <a href="/imodium-behandelen-diarree/">IMODIUM®</a> gebruiken.
                  Bij kinderen jonger dan 8 jaar kan je orale
                  rehydratatieoplossingen gebruiken om uitdrogingsverschijnselen
                  door diarree te voorkomen. Meer informatie hierover vind je in
                  het hoofdstuk{" "}
                  <a href="/diarree-bij-kinderen/">Diarree bij kinderen</a>.
                </p>
                <p />
              </div>
            </div>
            <div className="question">
              <h4>
                <a href="/veelgestelde-vragen-diarree/diarree-zijn-gang-te-laten-gaan-om-van-de-microben-af-te-raken/">
                  Is het niet beter diarree zijn gang te laten gaan om van de
                  microben af te raken?
                </a>
              </h4>
              <div className="answer">
                <p />
                <p>
                  Sommige mensen denken dat diarree een middel is waarmee het
                  lichaam de microben 'wegspoelt' die volgens hen de diarree
                  veroorzaken, en dat geneesmiddelen tegen diarree werken als
                  een kurk, waardoor de microben in het lichaam blijven zitten.
                </p>
                <p>
                  <strong>Dit strookt echter niet met de werkelijkheid.</strong>
                </p>
                <p>
                  Hoewel microben of eigenlijk bacteriën diarree kunnen
                  veroorzaken, is de oorzaak bij veel mensen gewoon stress of
                  angst, of gewoon een verandering van klimaat. Als er bacteriën
                  aanwezig zijn, pakt het immuunsysteem van het lichaam deze
                  meestal wel aan. Maar intussen kan{" "}
                  <a href="/imodium-behandelen-diarree/">IMODIUM®</a> ervoor
                  zorgen dat je jezelf&nbsp; beter voelt en je je gewone
                  activiteiten kunt voortzetten.
                </p>
                <p>
                  Door IMODIUM® te gebruiken, kan je helpen het verlies van
                  waterige stoelgang te voorkomen. Deze zorgt namelijk voor
                  verlies van vitale vloeistoffen en voedingsstoffen uit je
                  lichaam. Als de symptomen echter blijven aanhouden, moet je
                  advies inwinnen bij je dokter of apotheker.
                </p>
                <p />
              </div>
            </div>
            <div className="question">
              <h4>
                <a href="/veelgestelde-vragen-diarree/heb-ik-een-voorschrift-nodig-voor-imodium/">
                  Heb ik een voorschrift nodig voor IMODIUM®?
                </a>
              </h4>
              <div className="answer">
                <p />
                <p>
                  Neen,{" "}
                  <a href="/imodium-behandelen-diarree/imodiumreg-capsules/">
                    IMODIUM® capsules
                  </a>{" "}
                  en{" "}
                  <a href="/imodium-behandelen-diarree/imodium-instant/">
                    smelttabletten
                  </a>{" "}
                  zijn zonder voorschrift verkrijgbaar bij de apotheker.
                </p>
                <p />
              </div>
            </div>
            <div className="question">
              <h4>
                <a href="/veelgestelde-vragen-diarree/wat-is-de-oorzaak-van-acute-diarree/">
                  Waardoor wordt acute (kortstondige) diarree veroorzaakt?
                </a>
              </h4>
              <div className="answer">
                <p />
                <p>
                  <strong>Acute diarree </strong>kan worden veroorzaakt door
                  veel verschillende oorzaken. Enkele voorbeelden:
                </p>
                <ul>
                  <li>Virale of bacteriële infectie</li>
                  <li>
                    Allergie of intolerantie voor bepaalde voedingsmiddelen
                  </li>
                  <li>Te veel sterk gekruid of vet voedsel eten</li>
                  <li>Te veel alcohol drinken</li>
                  <li>Stress</li>
                  <li>Menstruatie</li>
                </ul>
                <p>
                  Voor meer informatie over acute diarree en de behandeling
                  ervan, neem een kijkje bij&nbsp;
                  <a href="/feiten-over-diarree/">Feiten over diarree</a>.
                </p>
                <p />
              </div>
            </div>
            <div className="question">
              <h4>
                <a href="/veelgestelde-vragen-diarree/wat-is-diarree/">
                  Wat is diarree?
                </a>
              </h4>
              <div className="answer">
                <p />
                <p>
                  Wanneer je spijsvertering normaal werkt, gaan voedsel en
                  vloeistoffen van de maag naar de dunne darm en vervolgens naar
                  de dikke darm. De dunne en de dikke darm zijn aan de
                  binnenkant voorzien van cellen die de nutriënten
                  (voedingsstoffen) en het water absorberen die het lichaam
                  nodig heeft.
                </p>
                <p>
                  De rest van het voedsel en het water verlaat vervolgens het
                  lichaam in de vorm van stoelgang.
                  <br />
                  Wanneer deze cellen geïrriteerd raken, kan de normaal gezien
                  trage, golfachtige beweging van de darmen overactief worden,
                  waardoor voedsel en vloeistoffen te snel door de dikke darm
                  gaan, waardoor er minder door het lichaam wordt geabsorbeerd.
                  Het resultaat hiervan is waterachtige stoelgang, beter gekend
                  als diarree.
                </p>
                <p>
                  Voor meer informatie, neem een kijkje bij&nbsp;
                  <a href="/feiten-over-diarree/oorzaken-van-diarree/">
                    Oorzaken van diarree
                  </a>
                  .
                </p>
                <p />
              </div>
            </div>
            <div className="question">
              <h4>
                <a href="/veelgestelde-vragen-diarree/moet-ik-diarree-behandelen/">
                  Moet ik diarree behandelen?
                </a>
              </h4>
              <div className="answer">
                <p />
                <p>
                  Kortstondige aanvallen van diarree kan je zelf behandelen,
                  tenzij je bijkomende symptomen krijgt zoals koorts, bloed of
                  slijm in je stoelgang; in dat geval moet je een arts
                  raadplegen. Symptomen van diarree kunnen worden verlicht door
                  het gebruik van{" "}
                  <a href="/imodium-behandelen-diarree/imodiumreg-capsules/">
                    IMODIUM® Capsules
                  </a>
                  &nbsp;en&nbsp;
                  <a href="/imodium-behandelen-diarree/imodium-instant/">
                    IMODIUM® Instant smelttabletten
                  </a>
                  ,&nbsp;die vaak verlichting kunnen bieden in slechts één
                  dosis.
                </p>
                <p>
                  IMODIUM® biedt u een uitgebreid gamma geneesmiddelen aan voor
                  uiteenlopende situaties:
                </p>
                <p>
                  Geneesmiddel zonder voorschrift verkrijgbaar in de apotheek,
                  drogisterij of supermarkt.
                </p>
                <table>
                  <thead>
                    <tr>
                      <th>Geneesmiddel</th>
                      <th>Beschikbare verpakkingsformaten</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>IMODIUM® Instant&nbsp;smelttabletten</td>
                      <td>10</td>
                    </tr>
                    <tr>
                      <td>IMODIUM® Capsules</td>
                      <td>10 of 20</td>
                    </tr>
                  </tbody>
                </table>
                <p>
                  Voor meer informatie, ga naar{" "}
                  <a href="/imodium-behandelen-diarree/welke-imodium-is-de-juiste/">
                    Welke IMODIUM® heb ik nodig
                  </a>
                  ?
                </p>
                <p />
              </div>
            </div>
          </div>
        </div>
        <Footer></Footer>
        <div id="myModal" className="reveal-modal large">
          <div
            className="flash-wrapper"
            style={{
              "max-width": "100%",
              height: "236px",
              width: "428px",
              margin: "0 auto"
            }}
          >
            <div id="flash">
              <p>
                Your browser must have Adobe Flash installed to see this
                animation.
              </p>
            </div>
          </div>
          <a className="close-reveal-modal">×</a>
        </div>
        <input type="hidden" name="language" defaultValue="nl-BE" id="lg" />
        <script src="//ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js" />
         <script src="https://code.jquery.com/jquery-migrate-3.2.0.js"></script>
        <script src="/assets/js/custom.js" />
        <script
          dangerouslySetInnerHTML={{
            __html:
              "window.jQuery || document.write('<script src=\"/assets/js/vendor/jquery-3.4.1.min.js\"><\\/script>')"
          }}
        />
        <script src="/assets/js/vendor/jquery.flexslider-min.js" />
        <script src="/assets/js/vendor/hash.js" />
        <script src="/assets/js/foundation/jquery.foundation.navigation.js" />
        <script src="/assets/js/foundation/jquery.foundation.topbar.js" />
        <script src="/assets/js/foundation/jquery.foundation.forms.js" />
        <script src="/assets/js/foundation/jquery.foundation.reveal.js" />
        <script src="/assets/js/vendor/swfobject.js" />
        <script src="/assets/js/vendor/yepnope.1.5.4-min.js" />
        <script src="/assets/js/app.js" />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\nfunction cookiesDirectiveScriptWrapper(){\n// declare teh used cookie-3rd-parties\n}\n"
          }}
        />
         
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n// The position of the disclosure ('top' or 'bottom')\n// Number of times to display disclosure. Enter 0 to show it forever!!!!\n// The URI of your privacy policy\ncookiesDirective('bottom',5,'/cookiebeleid');\n"
          }}
        />
      </div>
    );
  }
}

export default Page;
